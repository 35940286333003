@import './scss/variables';
@import './scss/style';

.typewriter {
  max-width: 70%;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid #037fff; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */ /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

.makeWhite {
  color: white !important;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #037fff; }
}